import SvgIcon from './SvgIcon'

export default {
  name: 'CalendarEvent',
  props: {
    title: String,
    data: Object,
    plansHtml: String,
    isBefore: Boolean,
  },
  computed: {
    status() {
      const lowerStatus = this.data?.surgery_information?.status?.toLowerCase()
      return lowerStatus.split(' ').join('-')
    }
  },
  render() {
    return (
      <div class={`fc-content fc-day-content fc-map event-${this.status}
        ${this.$props.isBefore && `event-${this.status}-outdated`}`}>
        <div class={`fc-title title-${this.status}`}>
          <span class="d-inline-block text-truncate day"> {this.$props.title} | <span domPropsInnerHTML={this.$props.plansHtml} /></span>
          <div class="fc-icon icon-tooltip">
            <svg viewBox="0 0 24 24" fill="none" stroke="#0C1D59" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 10H3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21 6H3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M21 14H3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M17 18H3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="fc-columns">
          {this.data?.surgery_information?.procedures ?
            <div class="items-row">
              {this.data?.surgery_information?.procedures.map(surgeryInformationProcedure => {
                return (
                  <div class={`fc-icon mt-1 mr-2 items icon-${this.status}`}>
                    <SvgIcon type="SURGICAL" />
                    <span class='item-name text-truncate'>{surgeryInformationProcedure.item?.name} /</span>
                  </div>
                )
              })}
            </div> : ''}
        </div>
      </div>
    )
  }
}
