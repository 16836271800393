<template>
  <div id="full-calendar">
    <Header
      title="Mapa cirúrgico"
      :fullCalendar="fullCalendar"
      :currentView="currentView"
      :currentMonth="currentMonth"
      :loadAppointments="reloadData"
      @changeDate="changeDate"
      @filterChanged="filterChanged"
      @changeProfessional="changeProfessional"
      @changeCurrentView="currentView = $event"
      @open-room-reservation-modal="$bvModal.show('room-reservation-modal')"
      :isSurgicalMapCurrentView="true"
    >
      <template #print>
        <b-button 
          variant="primary-outline" class="print-button" 
          @click="$bvModal.show('print-surgery-map-modal')"
        >
          <Printer />
        </b-button>
      </template>
    </Header>

    <Fullcalendar
      ref="calendar"
      class="fc fc-media-screen fc-direction-ltr fc-theme-standard"
      :options="calendarOptions"
      @hook:mounted="handleMounted"
    />

    <SurgeryInformationModal
      :patientId="patientId"
      :appointmentId="appointmentId"
      :appointment="appointment"
      @close-modal="closeSurgeryInformationModal"
      @update-surgery-status="updateSurgeryStatus"
    />

    <RoomReservationModal
      :date="date"
      :roomId="roomId"
      :roomReservation="roomReservation"
      :rooms="rooms"
      @close-room-reservation-modal="closeReservationModal"
      @reload-room-reservations="reloadData"
      @remove-room-reservation="removeRoomReservation"
    />

    <PrintSurgicalMapModal :clinicId="clinic.id" :options="rooms" :date="start"/>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import CalendarEvent from '@/components/SurgeryCenter/SurgicalMap/CalendarEvent'
import RoomReservationEvent from '@/components/SurgeryCenter/SurgicalMap/RoomReservationEvent'
import {
  getCurrentClinic,
  userHasPermission
} from '@/utils/localStorageManager'
import Tooltip from '@/modules/schedule/components/Tooltip'
import { BPopover } from 'bootstrap-vue'

export default {
  name: 'SurgicalMap',
  metaInfo: {
    title: 'Eyecare - Mapa Cirúrgico'
  },
  components: {
    Fullcalendar: () => import('@fullcalendar/vue'),
    Header: () => import('@/modules/schedule/layouts/Header'),
    SurgeryInformationModal: () =>
      import(
        '@/components/SurgeryCenter/SurgeryInformation/Modal/SurgeryInformationModal'
      ),
    RoomReservationModal: () =>
      import('@/components/Room/Modal/RoomReservationModal'),
    PrintSurgicalMapModal: () =>
      import(
        '@/components/SurgeryCenter/SurgicalMap/Modal/PrintSurgicalMapModal'
      ),
    Printer: () => import('@/assets/icons/printer.svg')
  },
  computed: {
    ...mapState('schedule', ['loadingApointments', 'selectedSchedules', 'surgicalEvents']),
  },
  data() {
    return {
      currentView: 'resourceTimeline',
      clinic: getCurrentClinic(),
      currentMonth: this.moment().format('MM'),
      start: this.moment().format('YYYY-MM-DD 00:00:00'),
      appointmentId: '',
      appointment: {},
      date: new Date(),
      patientId: '',
      roomId: '',
      rooms: [],
      patient: {},
      roomReservation: {},
      filters: {
        professionalIds: [],
        professional_id: null,
        financeiro_value: null,
        room_id: null,
        procedure_type: null
      },

      calendarOptions: {
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        height: '100%',
        plugins: [
          resourceTimelinePlugin,
          resourceTimeGridPlugin,
          interactionPlugin
        ],
        initialView: 'resourceTimeline',
        headerToolbar: false,
        aspectRatio: 1.5,
        resourceAreaHeaderContent: 'Salas',
        resources: [],
        events: [],
        eventMinWidth: 100,
        eventShortHeight: 50,
        slotMinWidth: 50,
        resourceAreaWidth: '20%',
        slotDuration: '00:30:00',
        eventColor: 'transparent',
        datesAboveResources: true,
        nowIndicator: true,
        allDaySlot: false,
        eventContent: this.handleEventContent,
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick
      },
      fullCalendar: null
    }
  },
  async created() {
    try {
      await this.getClinicSurgeryRooms()
      await this.reloadData()
    } catch (error) {
      this.$toast.error(error.message)
    }
  },
  methods: {
    ...mapActions('schedule', ['getSurgicalMapAppoinments']),
    handleMounted() {
      this.fullCalendar = this.$refs.calendar
    },
    async reloadData() {
      this.filters.professionalIds = this.selectedSchedules.map(schedule => schedule.id)
      this.getSurgicalMapData()
    },
    async getClinicSurgeryRooms() {
      try {
        const { data } = await this.api.getClinicSurgeryRooms(this.clinic.id)
        this.calendarOptions.resources = data.map(room => {
          return {
            id: room.id,
            title: room.name
          }
        })
        this.rooms = data.map(room => {
          return {
            id: room.id,
            label: room.name
          }
        })
      } catch (error) {
        throw new Error({
          ...error,
          message: 'Erro ao buscar salas'
        })
      }
    },
    getSurgicalMapData() {
      this.getSurgicalMapAppoinments({ start: this.start, filters: this.filters })
    },
    removeRoomReservation (id) {
      this.calendarOptions.events = this.calendarOptions.events.filter(event => event.id !== id)
    },
    async changeDate(calendarRange) {
      const start = this.moment(this.start).format('YYYY-MM-DD')
      const startRange = calendarRange.startDate.format('YYYY-MM-DD')

      if (startRange === start) return
      this.start = startRange
      await this.reloadData()
    },
    closeReservationModal() {
      this.roomReservation = {}
      this.roomId = ''
    },
    mountTooltip(content, data, element) {
      if (!element.querySelector('.fc-title .icon-tooltip')) return
      new BPopover({
        propsData: {
          html: true,
          content: (
            <Tooltip
              content={content}
              data={data}
              hasProcedure={this.hasProcedure}
            />
          ),
          placement: 'bottom',
          boundary: 'scrollParent',
          boundaryPadding: 5,
          offset: 0,
          triggers: 'hover',
          target: element.querySelector('.fc-title .icon-tooltip')
        }
      }).$mount()
    },
    handleEventContent(arg) {
      const event = arg.event
      const content = event._def
      const isBefore = this.moment(event.start).isBefore(
        this.moment().startOf('day')
      )
      const { data } = event.extendedProps
      if (data?.type) {
        const instance = this.mountInstance(RoomReservationEvent, { data })
        return {
          domNodes: [instance.$el]
        }
      }
      const { plans, appointment } = event.extendedProps

      const plansHtml = plans
        .map(p => `<span class="text-truncate text-plan">${p}</span>`)
        .join(' /')

      const instance = this.mountInstance(CalendarEvent, {
        title: event.title,
        data: appointment,
        plansHtml,
        isBefore
      })

      this.mountTooltip(content, appointment, instance.$el)
      return {
        domNodes: [instance.$el]
      }
    },
    hasProcedure(data, procedureType) {
      return data.appointment_items.some(p => p.item?.type === procedureType)
    },
    changeProfessional(professional) {
      this.filters.professional_id = professional?.id
    },
    filterChanged(filters) {
      this.filters.financeiro_value = filters.financeiroValue
      this.filters.room_id = filters.roomId
      this.filters.procedure_type = filters.procedureType
    },
    handleDateClick(arg) {
      if (!userHasPermission('FpAgen2')) {
        this.$toast.warning('Você não possui permissão para criar agendamentos')
        return
      }
      this.date = arg.date
      this.roomId = arg.resource?._resource?.id
      this.$bvModal.show('room-reservation-modal')
    },
    handleEventClick(arg) {
      if (arg.event.extendedProps?.type) {
        this.roomReservation = arg.event.extendedProps.data
        this.$bvModal.show('room-reservation-modal')
        return
      }
      this.appointmentId = arg.event.extendedProps.appointment.id
      this.appointment = arg.event.extendedProps.appointment
      this.patientId = arg.event.extendedProps.appointment?.patient?.id
      this.$bvModal.show('surgery-information-modal')
    },
    closeSurgeryInformationModal() {
      this.appointmentId = ''
      this.patientId = ''
      this.date = null
    },
    updateSurgeryStatus(surgeryInformation) {
      const index = this.calendarOptions.events.findIndex(
        event =>
          event?.appointment?.surgery_information?.id === surgeryInformation.id
      )
      if (index === -1) return

      this.$set(this.calendarOptions.events, index, {
        ...this.calendarOptions.events[index],
        appointment: {
          ...this.calendarOptions.events[index].appointment,
          surgery_information: {
            ...this.calendarOptions.events[index].appointment
              .surgery_information,
            ...surgeryInformation
          }
        }
      })
    },
    mountInstance(Component, data) {
      const Constructor = Vue.extend(Component)
      const instance = new Constructor({
        propsData: {
          ...data
        }
      })
      instance.$mount()
      return instance
    }
  },
  watch: {
    async selectedSchedules() {
      this.filters.professional_id = null
      this.filters.room_id = null

      if (this.selectedSchedules?.length === 1 && this.selectedSchedules[0].type === 'doctor') {
        this.filters.professional_id = this.selectedSchedules[0].id
      }

      if (this.selectedSchedules?.length === 1 && this.selectedSchedules[0].type === 'room') {
        this.filters.room_id = this.selectedSchedules[0].id
      }

      await this.reloadData()
    },
    surgicalEvents() {
      this.calendarOptions.events = this.surgicalEvents
      console.log('this.surgicalEvents  -->  ', this.surgicalEvents)
    }
  }
}
</script>

<style lang="scss" src="@/modules/schedule/styles/calendar.scss" />
<style lang="scss" src="./styles.scss" />
