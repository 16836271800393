export default {
  name: 'RoomReservationEvent',
  props: {
    data: Object,
  },
  render() {
    return (
      <div class={`fc-content fc-day-content ${this.$props.data.type}`}>
        <div class="fc-title mb-auto">
          <span class="d-inline-block text-truncate day"> {this.$props.data.title}</span>
        </div>
        {this.$props.data?.professional?.name
          ? <div class="fc-columns mt-2">
            <span class="item-name text-truncate">
              Dr. {this.$props.data.professional?.name}
            </span>
          </div> : ''
        }
      </div>
    )
  }
}
